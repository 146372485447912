import * as React from "react";
import tw, { css } from "twin.macro";
import Layout from "../../components/Layout";
import Button from "../../components/Button";
import MessageScreen from "../../components/MessageScreen";
import { navigate } from "gatsby";

// markup
/** @ts-ignore */
const TieBreakYesPage = ({ location }) => {
  const [thankyou, setThankyou] = React.useState(false);
  const [nickname, setNickname] = React.useState("");

  async function sendForm() {
    const body = "nickname=" + encodeURIComponent(nickname);
    const response = await fetch("/submit.php", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body,
    });
    setThankyou(true);
  }

  function backToMap() {
    navigate("/");
  }

  return (
    <Layout>
      {thankyou ? (
        <MessageScreen onClose={backToMap}>
          <MessageScreen.Text>{`Danke ${nickname}.`}</MessageScreen.Text>
          <Button tw="mt-4 min-w-30" onClick={backToMap}>
            Zur Karte
          </Button>
        </MessageScreen>
      ) : (
        <MessageScreen>
          <MessageScreen.Text>
            Reiche dein Ergebnis jetzt beim Gamemaster ein.
          </MessageScreen.Text>
          <div tw="relative h-7 w-[82.5%] max-w-[310px] mt-3">
            <input
              css={[
                tw`block w-full h-full rounded-0.5 border-secondary border-[1px] bg-primary text-default px-2 font-16`,
                css`
                  &:focus,
                  &:not(:placeholder-shown) {
                    + span {
                      transform: translate3d(0, 0, 0) scale(1);
                    }
                  }
                `,
              ]}
              placeholder=" "
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
            />
            <span
              css={[
                tw`pointer-events-none absolute font-12 text-secondary transition-transform top--1 left-1.5 px-0.5 bg-primary`,
                css`
                  transform: translate3d(12px, 26px, 0) scale(1.5);
                `,
              ]}
            >
              Name
            </span>
          </div>
          <Button tw="mt-4 min-w-30" onClick={sendForm}>
            Jetzt abschicken
          </Button>
        </MessageScreen>
      )}
    </Layout>
  );
};

export default TieBreakYesPage;
